import * as React from "react";
import Frame from "../components/Frame";
import { EmbedProps as Props } from ".";

function MSStream2(props: Props) {
    const { matches } = props.attrs;
    const videoId = matches[1];
    return (
        <Frame
            {...props}
            src={`https://noltelauth.sharepoint.com/sites/NL/_layouts/15/embed.aspx?UniqueId=${videoId}&embed=%7B%22hvm%22%3Atrue%2C%22ust%22%3Atrue%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen`}
            title={`Microsoft Stream 2 (${videoId})`}
        />
    );
}

MSStream2.ENABLED = [
    /([a-zA-Z0-9_-]{36})$/i,
];

export default MSStream2;